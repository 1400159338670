<template>
  <div class="content edit">
    <h1>Edit Account</h1>

    <template v-if="formSuccess">
      <b-alert
        variant="success"
        show
      >
        Changes saved!
      </b-alert>

      <b-button
        variant="primary"
        @click="$router.push({name: 'main'})"
      >
        OK
      </b-button>
    </template>
    <template v-else>
      <b-alert
        v-if="formErrors.non_field_errors !== undefined"
        variant="danger"
        show
      >
        {{ formErrors.non_field_errors[0] }}
      </b-alert>

      <b-form @submit.prevent="submitForm">
        <b-form-group
          label="First name"
          label-for="first_name"
        >
          <b-form-input
            id="first_name"
            v-model="formFields.first_name"
            required
            :state="formErrors.first_name === undefined ? null : false"
            :aria-describedby="formErrors.first_name === undefined ? false : 'first_name-feedback'"
          />
          <b-form-invalid-feedback
            v-if="formErrors.first_name !== undefined"
            id="first_name-feedback"
          >
            {{ formErrors.first_name[0] }}
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          label="Last name"
          label-for="last_name"
        >
          <b-form-input
            id="last_name"
            v-model="formFields.last_name"
            required
            :state="formErrors.last_name === undefined ? null : false"
            :aria-describedby="formErrors.last_name === undefined ? false : 'last_name-feedback'"
          />
          <b-form-invalid-feedback
            v-if="formErrors.last_name !== undefined"
            id="last_name-feedback"
          >
            {{ formErrors.last_name[0] }}
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          label="Email address"
          label-for="email"
        >
          <b-form-input
            id="email"
            v-model="formFields.email"
            type="email"
            required
            :state="formErrors.email === undefined ? null : false"
            :aria-describedby="formErrors.email === undefined ? false : 'email-feedback'"
          />
          <b-form-invalid-feedback
            v-if="formErrors.email !== undefined"
            id="email-feedback"
          >
            {{ formErrors.email[0] }}
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          label="Bio"
          label-for="bio"
        >
          <b-form-textarea
            id="textarea"
            v-model="formFields.bio"
            placeholder="Tell others about yourself. What are your goals? Why are taking these courses? Who do you want to be?"
            rows="6"
          />
        </b-form-group>

        <b-form-checkbox
          v-model="formFields.change_password"
          name="change_password"
        >
          I want to change my password
        </b-form-checkbox>

        <transition-slide mode="out-in">
          <div
            v-if="formFields.change_password"
            class="password-fields"
          >
            <b-form-group
              label="Current password"
              label-for="current_password"
            >
              <b-form-input
                id="current_password"
                v-model="formFields.current_password"
                type="password"
                required
                :state="formErrors.current_password === undefined ? null : false"
                :aria-describedby="formErrors.current_password === undefined ? false : 'current_password-feedback'"
              />
              <b-form-invalid-feedback
                v-if="formErrors.current_password !== undefined"
                id="current_password-feedback"
              >
                {{ formErrors.current_password[0] }}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
              label="New password"
              label-for="new_password1"
            >
              <b-form-input
                id="new_password1"
                v-model="formFields.new_password1"
                type="password"
                required
                :state="formErrors.new_password1 === undefined ? null : false"
                :aria-describedby="'new_password1-help' + (formErrors.new_password1 === undefined ? '' : ' new_password1-feedback')"
              />
              <b-form-invalid-feedback
                v-if="formErrors.new_password1 !== undefined"
                id="new_password1-feedback"
              >
                {{ formErrors.new_password1[0] }}
              </b-form-invalid-feedback>
              <b-form-text id="new_password1-help">
                <ul>
                  <li>Your password can't be too similar to your other personal information.</li>
                  <li>Your password must contain at least 8 characters.</li>
                  <li>Your password can't be a commonly used password.</li>
                  <li>Your password can't be entirely numeric.</li>
                </ul>
              </b-form-text>
            </b-form-group>

            <b-form-group
              label="New password confirmation"
              label-for="new_password2"
            >
              <b-form-input
                id="new_password2"
                v-model="formFields.new_password2"
                type="password"
                required
                :state="formErrors.new_password2 === undefined ? null : false"
                :aria-describedby="formErrors.new_password2 === undefined ? false : 'new_password2-feedback'"
              />
              <b-form-invalid-feedback
                v-if="formErrors.new_password2 !== undefined"
                id="new_password2-feedback"
              >
                {{ formErrors.new_password2[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
        </transition-slide>

        <b-form-checkbox
          v-model="formFields.change_profile_picture"
          name="change_profile_picture"
        >
          I want to change my profile picture
        </b-form-checkbox>

        <transition-slide mode="out-in">
          <div
            v-if="formFields.change_profile_picture"
            class="profile-picture-content"
          >
            <b-form-file
              v-model="formFields.profile_picture"
              :state="formErrors.profile_picture === undefined ? null : false"
              placeholder="Choose a picture or drop it here..."
              drop-placeholder="Drop picture here..."
              accept="image/gif, image/jpeg, image/png"
              :aria-describedby="userData.profile_picture ? 'profile_picture-help_text' : false"
              @change="setProfilePictureNonCropped"
            />

            <b-form-text
              v-if="userData.profile_picture"
              id="profile_picture-help_text"
            >
              Leave blank if you would like to delete your current profile
              picture without replacing it.
            </b-form-text>

            <div
              v-if="profilePictureNonCroppedStatus == 'loading'"
              class="cropper-loading-message"
            >
              Loading...
            </div>

            <template v-if="profilePictureNonCropped">
              <vue-cropper
                ref="cropper"
                :view-mode="2"
                :aspect-ratio="1"
                :guides="false"
                :background="false"
                :auto-crop-area="0.5"
                :zoomable="false"
                :src="profilePictureNonCropped"
                :ready="cropperReady"
              />
            </template>
          </div>
        </transition-slide>

        <div class="mb-3">
          <router-link
            :to="{ name: 'manage_programs' }"
          >
            I want to manage my programs
          </router-link>
        </div>

        <b-button
          type="submit"
          variant="primary"
          :disabled="formSubmitting"
        >
          <template v-if="formSubmitting">
            Saving Changes
            <spinner />
          </template>
          <template v-else>
            Save Changes
          </template>
        </b-button>

        <b-button
          type="button"
          variant="secondary"
          :disabled="formSubmitting"
          @click="$router.push({name: 'main'})"
        >
          Cancel
        </b-button>
      </b-form>
    </template>
  </div>
</template>

<script>
import 'cropperjs/dist/cropper.css';
import VueCropper from 'vue-cropperjs';
import Spinner from '@/components/Spinner.vue';
import TransitionSlide from '@/components/TransitionSlide.vue';

export default {
  name: 'EditAccount',
  components: {
    VueCropper,
    Spinner,
    TransitionSlide,
  },
  data() {
    return {
      formSubmitting: false,
      formErrors: {},
      formSuccess: false,
      formFields: {
        first_name: '',
        last_name: '',
        email: '',
        bio: '',
        change_password: false,
        current_password: '',
        new_password1: '',
        new_password2: '',
        change_profile_picture: false,
        profile_picture: null,
      },
      profilePictureNonCroppedStatus: 'idle',
      profilePictureNonCropped: '',
    };
  },
  computed: {
    userData() {
      return this.$store.state.userData;
    },
  },
  metaInfo: {
    title: 'Edit Account',
  },
  created() {
    this.formFields.first_name = this.userData.first_name;
    this.formFields.last_name = this.userData.last_name;
    this.formFields.email = this.userData.email;
    this.formFields.bio = this.userData.bio;
  },
  methods: {
    cropperReady() {
      this.profilePictureNonCroppedStatus = 'loaded';
    },
    dataURItoFile(dataURI, filename) {
      const bytes = dataURI.split(',')[0].indexOf('base64') >= 0
        ? atob(dataURI.split(',')[1])
        : unescape(dataURI.split(',')[1]);
      const mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
      const max = bytes.length;
      const ia = new Uint8Array(max);

      for (let i = 0; i < max; i += 1) {
        ia[i] = bytes.charCodeAt(i);
      }

      return new File([ia], filename, { type: mime });
    },
    setProfilePictureNonCropped(e) {
      const file = e.target.files[0];

      if (file) {
        this.profilePictureNonCroppedStatus = 'loading';

        const reader = new FileReader();

        reader.onload = (event) => {
          this.profilePictureNonCropped = event.target.result;

          this.$nextTick(() => {
            this.$refs.cropper.replace(event.target.result);
          });
        };

        reader.readAsDataURL(file);
      } else {
        this.profilePictureNonCropped = '';
      }
    },
    async submitForm() {
      this.formErrors = {};
      this.formSubmitting = true;

      const postData = JSON.parse(JSON.stringify(this.formFields));

      if (!this.formFields.change_password) {
        delete postData.current_password;
        delete postData.new_password1;
        delete postData.new_password2;
      }
      if (postData.bio === '') delete postData.bio;

      delete postData.profile_picture;

      const body = new FormData();

      Object.keys(postData).forEach((key) => {
        body.append(key, postData[key]);
      });

      if (this.formFields.change_profile_picture && this.profilePictureNonCropped) {
        const imageType = this.profilePictureNonCropped.substring(
          5,
          this.profilePictureNonCropped.indexOf(';'),
        );

        body.append(
          'profile_picture',
          this.dataURItoFile(
            this.$refs.cropper.getCroppedCanvas({
              imageSmoothingQuality: 'high',
              width: 144,
              height: 144,
            }).toDataURL(imageType),
            `${this.userData.id}-${Date.now()}.${imageType.slice(6)}`,
          ),
        );
      }

      let response;

      try {
        response = await fetch(
          `${process.env.VUE_APP_API_URL}users/${this.userData.id}/`,
          {
            method: 'PATCH',
            headers: {
              Authorization: `Token ${this.userData.drf_token}`,
            },
            body,
          },
        );
      } catch (e) {} // eslint-disable-line no-empty

      this.formSubmitting = false;

      if (response && [200, 400, 401].includes(response.status)) {
        const responseJson = await response.json();

        if (response.status === 200) {
          this.$store.commit('userData', responseJson);
          this.formSuccess = true;
        } else if (response.status === 400) {
          this.formErrors = responseJson;
        } else {
          this.logUserOut();
        }
      } else {
        this.formErrors = {
          non_field_errors: [
            'Unable to communicate with the server. Please check your '
            + 'connection and try again.',
          ],
        };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.edit {
  max-width: 500px;
}

.custom-checkbox {
  margin-bottom: 1rem;
}

.password-fields {
  &:after {
    content: '';
    display: block;
    height: 1rem;
  }

  .form-group:last-child {
    margin-bottom: 0;
  }
}

.profile-picture-content:after {
  content: '';
  display: block;
  height: 1rem;
}

.custom-file {
  z-index: 0;
}

.cropper-loading-message {
  margin-top: 1rem;
  text-align: center;
}

/deep/ .cropper-container {
  margin-top: 1rem;
}
</style>
